html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: sans-serif;
}

@media (max-width: 959.95px) {
  #root {
    height: auto;
  }
}

* {
  -webkit-overflow-scrolling: touch;
}
