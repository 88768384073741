.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
  }

  /*
    helps the browser figure out how to break content
    will only print single page if removed
   */
  body > div,
  main,
  .App,
  .printContentContainer {
    display: inline !important;
  }
  .App > header,
  footer,
  footer div {
    display: none !important;
  }

  /*
    Mozilla has a nasty rouding bug for borders,
    Need the border to be at least 2 to show
  */
  /* table td {
    border: 2px solid #333 !important;
  } */
}

@page {
  size: A4;
}

/* Styles from SCSS files */

.emptyState,
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media (max-width: 959.95px) {
    height: 100vh;
  }
}

.mainView {
  width: 100%;
  height: 100%;
  @media (max-width: 959.95px) {
    overflow: visible;
  }
}

.mainContainer {
  /* overall height - header - footer */
  width: 100%;
  height: 100%;
  overflow: auto;
  @media (max-width: 959.95px) {
    overflow: visible;
  }
}

.mainContainer .calendarView,
.mainContainer .vehiclesView {
  position: relative;
  display: flex;
  height: 100%;

  @media (max-width: 959.95px) {
    height: auto;
  }
}

.vehiclesView .sideBarContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vehiclesView .sideBar {
  overflow: hidden;
}
